import React, { useEffect } from "react";
import Navigation from "../components/navigation/navigation";
import Footer from "../components/footer/footer";
import { HeadFC } from "gatsby";
import SEO from "../components/seo/seo";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";

// CSS
import StylesComponent from "../components/styles/styles-component";
import "../sass/page-specific/event-styles.scss";
import "../sass/page-specific/about-styles.scss";


const AboutUs: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/3gN9FlUzfnTgHJX5qrvZVT/14a3a9a4731cdab43ad5e74a53eee8ea/travel-experience.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>About us</h1>
                  <p style={{maxWidth: 320}} className="secondary-text">Welcome to Prestige VIP. The premium concierge service for hospitality and luxury experiences.</p>

                  <br/>

                  <a href="/contact" className="standard-button orange">Get in touch</a>
               </div>
            </div>
         </div>

         <div className="standard-container" id="container-main">
            <div className="standard-inner-container about-us-width">
               <div className="about-us-split-container">
                  <div className="about-us-split-text">
                     <h2>Our mission</h2>
                     <p>At Prestige VIP our goal is to modernise the hospitality and luxury experience sector by offering a digitally-driven personalised concierge service that makes your experiences with us easy, luxurious and unforgettable.</p>
                  </div>

                  <div className="mission-image-wrapper">
                     <img src="/images/yacht-bahamas.jpg" className="about-us-image"/>
                     <p className="mission-image-caption">Our customers can request any event or experience and we'll work tirelessly to make it happen</p>
                  </div>
               </div>
               
               <div className="about-us-split-container reverse">
                  <img className="about-us-image" src="/images/prestige-vip-customer.jpg"/>
                  <div className="about-us-split-text reverse">
                     <h2>How to use Prestige VIP?</h2>
                     <p>Due to the bespoke nature of the majority of our customers' requests, we generally prefer to speak with you over the phone. However, you can submit your request online, and we'll get back to you when we've sourced your experience.</p>
                     <br/>
                     <a href="/experiences" className="standard-button orange">Find experiences</a>
                  </div>
               </div>
            </div>
         </div>

         <div className="standard-container" style={{paddingTop: 0, marginTop: -30}}>
            <div className="standard-inner-container" style={{width: '100%'}}>
               <div className="about-us-maps-wrapper">
                  <h2>Where are we?</h2>
                  <p>We are based in Croydon, Greater London</p>

                  <iframe id="about-us-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d307.0084714241455!2d-0.09526811615928153!3d51.378185006673064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487607329b228701%3A0x18ebc24259eabefe!2sMelrose%20House!5e0!3m2!1sen!2suk!4v1675769436898!5m2!1sen!2suk" width="100%" height="450" style={{border: 'none'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
               </div>
            </div>
         </div>

         <div className="standard-container" id="container-careers" style={{marginTop: -30}}>
            <div className="standard-inner-container about-us-width">
            <div className="about-us-split-container">
                  <div className="about-us-split-text">
                     <h2>Want to work with us?</h2>
                     <p>We're always on the look out for top talent, whether you are a sales person, software engineer or marketing expert we'd love to hear from you. Click on the following link to visit our careers page on Indeed to see available vacancies.</p>
                     <br/>
                     <a href="https://uk.indeed.com/cmp/Prestige-Vip/jobs" className="standard-button orange" target="_blank">Visit careers</a>
                  </div>

                  <img src="/images/prestige-vip-engineer.jpg" className="about-us-image"/>
               </div>
            </div>
         </div>


         <Footer/>
      </React.Fragment>
   )
}

export default AboutUs

export const Head: HeadFC = () => (
   <SEO
      title="About us | Prestige VIP"
      metaDescription="Welcome to Prestige VIP. The premium concierge service for hospitality and luxury experiences."
      slug="/about-us"
   />
)